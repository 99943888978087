var _ = require('utils/utils');
var dom = require('utils/dom');

var SlideshowView = function(options){


	this.options = _.extend({
		el: null
	}, options || {});


	this.$el     = this.options.el;
	this.$slides = dom.queryAll( '.js-slide', this.$el );
	this.$next   = dom.queryAll('.js-slide-next', this.$el );
	this.$prev   = dom.queryAll('.js-slide-prev', this.$el );

	this.$grid = dom.query('.js-grid', this.$el );
	this.$gridCell = dom.queryAll('.js-grid-cell', this.$el );

	this.currentIndex = 0;

	var i;

	for(i=0; i< this.$next.length; i++){
    	dom.addEventListener( this.$next[i], 'click', this.onNextBtnClick.bind(this) );
	}

	for(i=0; i< this.$prev.length; i++){
    	dom.addEventListener( this.$prev[i], 'click', this.onPrevBtnClick.bind(this) );
	}

	if( this.$grid != null ){
		this.initGrid();
	}

    this.setActiveSlide(0);


};

SlideshowView.prototype = {

	initGrid: function(){

		this.gridCellArr = [];
		this.slidesArr = [];

		for(var i=0; i< this.$gridCell.length; i++){
	    	this.gridCellArr[i] = {
	    		el: this.$gridCell[i],
	    		tag: this.$gridCell[i].getAttribute("data-tag")
	    	};
		}

		for(var i=0; i< this.$slides.length; i++){
	    	this.slidesArr[i] = {
	    		el: this.$slides[i],
	    		tag: this.$slides[i].getAttribute("data-tag").split(',')
	    	};
		}

		this.hasGrid = true;

	},

	setActiveSlide: function( index ){

		this.currentIndex = index;

		if( this.currentIndex % 2 == 0 ){
			dom.addClass('is-odd', this.$el );
		} else {
			dom.removeClass('is-odd', this.$el );
		}

		for(var i=0; i< this.$slides.length; i++){

			if( i == this.currentIndex ){

				dom.addClass('is-active', this.$slides[i]);
				dom.removeClass('to-right', this.$slides[i]);
				dom.removeClass('to-left', this.$slides[i]);

				if( this.hasGrid ){

					for(var k=0; k<this.gridCellArr.length; k++){
				    	dom.removeClass('is-active', this.gridCellArr[k].el );
					}

					for(var j=0; j< this.slidesArr[i].tag.length; j++){
						for(var k=0; k<this.gridCellArr.length; k++){
					    	if( this.gridCellArr[k].tag == this.slidesArr[i].tag[j] ){
					    		dom.addClass('is-active', this.gridCellArr[k].el );
					    	}
						}
					}
				}

			} else {
				if( i < this.currentIndex ){
					dom.addClass('to-left', this.$slides[i]);
					dom.removeClass('to-right', this.$slides[i]);
				} else {
					dom.addClass('to-right', this.$slides[i]);
					dom.removeClass('to-left', this.$slides[i]);
				}
				dom.removeClass('is-active', this.$slides[i]);
			}
		}
	},

    onNextBtnClick: function(){
    	var currentIndex;
    	if( this.currentIndex < this.$slides.length-1 ){
    		currentIndex = this.currentIndex + 1;
    	} else {
    		currentIndex = 0;
    	}
    	this.setActiveSlide(currentIndex);
    },

    onPrevBtnClick: function(){
    	var currentIndex;
    	if( this.currentIndex > 0 ){
    		currentIndex = this.currentIndex - 1;
    	} else {
    		currentIndex = this.$slides.length-1;
    	}
    	this.setActiveSlide(currentIndex);
    },

};




module.exports = SlideshowView;