var support = require('./utils/BrowserSupport');
var $setTimeout = require('utils/$setTimeout');
var dom = require('utils/dom');


var SlideshowView = require('./SlideshowView');

Application = {

    initialize: function(){

      this.$doc  = document.documentElement || document;
      this.$win  = window;
      this.$body = document.getElementsByTagName("body")[0];

      this.$parallax = dom.queryAll( '.js-parallax' );
      this.$preview  = dom.queryAll('.js-preview');

      this.$colLeft   = dom.query('.js-col-left');
      this.$colRight  = dom.query('.js-col-right');
      this.$burger    = dom.query('.js-burger');
      this.$nav       = dom.query('.js-nav');
      this.$intro     = dom.query('.js-wrapper-intro');
      this.$top       = dom.query('.js-link-top');
      this.$nextItem  = dom.query('.js-next-item');
      this.$prevItem  = dom.query('.js-prev-item');

      this.$nextNews  = dom.query('.js-next-news');
      this.$prevNews  = dom.query('.js-prev-news');
      this.$currentNews = dom.query('.js-news-current');

      this.$nbItems     = dom.query('.js-chapter-total');
      this.$currentItem = dom.query('.js-chapter-current');

      this.$nbNews      = dom.query('.js-news-total');
      this.$currentNews = dom.query('.js-news-current');

      this.$anchor      = dom.query('.js-anchor');

      this.$popin = dom.query('.js-popin');
      this.$popinContainer = dom.query('.js-popin-container');

      this.nextNews = 0;
      this.prevNews = 0;


      this.$wrapperBig = dom.queryAll('.js-wrapper-big');
      this.$wrapperAside = dom.queryAll('.js-wrapper-aside');
      this.$wrapperImg = dom.queryAll('.js-wrapper-image');
      this.$wrapperTxt = dom.queryAll('.js-wrapper-text');
      this.$wrapperDesc = dom.queryAll('.js-wrapper-desc');

      this.wrapperBigArr = this.$wrapperBig.length;
      this.wrapperAsideArr = this.$wrapperAside.length;
      this.wrapperImgArr = this.$wrapperImg.length;
      this.wrapperTxtArr = this.$wrapperTxt.length;
      this.wrapperDescArr = this.$wrapperDesc.length;

      this.currentSlide  = 0;

      this.activeNews =  0;


      this.resizeTimer = null;
      this.winHeight = 0;
      this.winWidth = 0;
      this.halfWinHeight = 0;
      this.halfWinWidth = 0;

      this.winWidth  = this.$win.innerWidth || this.$doc.clientWidth || this.$body.clientWidth;
      this.winHeight = this.$win.innerHeight|| this.$doc.clientHeight|| this.$body.clientHeight;

      this.bodyHeight =   0;
      this.windowHeight = 0;
      this.windowWidth =  0;
      this.scrollTop =    0;
      this.scrollRatio =  0;

      this.numParallax = this.$parallax.length;
      this.objectsArr = new Array( this.numParallax );
      for(var i=0; i<this.numParallax; i++){
        this.objectsArr[i] = {
          el: this.$parallax[i],
          ratio: this.$parallax[i].getAttribute('data-ratio')
        };
      }

      document.addEventListener("scroll", this.onScroll.bind(this) );
      dom.addEventListener( this.$win, 'resize', this.onResize.bind(this) );
      dom.addEventListener( this.$win, 'orientationchange', this.onResize.bind(this) );

      if( this.$nbItems !== null ){
        this.$nbItems.innerHTML = this.$nbItems.innerHTML + this.wrapperBigArr;
      }

      if( this.$nbNews !== null ){
        this.$nbNews.innerHTML = this.$nbNews.innerHTML + this.wrapperTxtArr;
      }

      if( this.$anchor !== null ){
        dom.addEventListener( this.$anchor, 'click', this.onClickAnchor.bind(this) );
      }


      if( this.$colLeft !== null ){
        dom.addEventListener( this.$colLeft, 'mouseover', this.onColLeftHover.bind(this) );
        dom.addEventListener( this.$colRight, 'mouseover', this.onColRightHover.bind(this) );
      }

      if( this.$prevItem !== null ){
        dom.addEventListener( this.$nextItem, 'click', this.onNextItem.bind(this) );
        dom.addEventListener( this.$prevItem, 'click', this.onPrevItem.bind(this) );
      }

      if( this.$nextNews !== null ){
        dom.addEventListener( this.$nextNews, 'click', this.onNextNewsClick.bind(this) );
        dom.addEventListener( this.$prevNews, 'click', this.onPrevNewsClick.bind(this) );
      }


      dom.addEventListener( this.$burger, 'click', this.onBurgerClick.bind(this) );

      if( this.$top !== null ){
        dom.addEventListener( this.$top, 'click', this.onTopClick.bind(this) );
      }

      if( this.$preview !== null ){

        for (var i = 0; i < this.$preview.length; i++) {
          dom.addEventListener( this.$preview[i], 'click', this.onPreviewClick.bind(this) );
        };

      }

      if( this.$popin !== null ){
        dom.addEventListener(this.$popin, 'click', this.onPopinClick.bind(this));
      }


      this.onResize();
      this.onEnterFrame();

      $setTimeout(function(){
        window.scrollTo(0,0);
        dom.addClass( 'is-ready', this.$body );
      }, 100, this );

    },

    onResize: function () {

      var winWidth  = this.$win.innerWidth || this.$doc.clientWidth || this.$body.clientWidth;
      var winHeight = this.$win.innerHeight|| this.$doc.clientHeight|| this.$body.clientHeight;

      //IE 7/8 willt triggerresize event on dom node size change
      if( winWidth == this.lastWinWidth && winHeight == this.lastWinHeight ){
        return;
      }

      this.winWidth  = winWidth;
      this.winHeight = winHeight;
      this.lastWinWidth  = this.winWidth;
      this.lastWinHeight = this.winHeight;

      //working alternative for ios7 height issue but hardcoded :
      if (support.isIOS7 && this.winWidth > this.winHeight)
        this.winHeight -= 20;

      this.halfWinHeight = this.winHeight >> 1;
      this.halfWinWidth = this.winWidth >> 1;
      this.quarterWinWidth = this.halfWinWidth >> 1;
      this.quarterWinHeight = this.halfWinHeight >> 1;
      this.winRatio = this.winWidth / this.winHeight;  

      if( this.$intro !== null ){

        this.heightIntro = this.$intro.clientHeight;

      }

    },

    onScroll: function () {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    },

    onClickAnchor: function () {

    },
  
    onEnterFrame: function(){

      requestAnimationFrame( this.onEnterFrame.bind(this) );

      if ( this.winWidth > 1024 ) {      
        for(var i=0; i< this.numParallax; i++){
          this.objectsArr[i].el.style[support.transform] = "translateY("+( ( this.scrollTop -  this.scrollRatio ) *  this.objectsArr[i].ratio )+"px) translateZ(0)";
        }
      }

      if ( this.winWidth > 1024 ) {
        if (this.scrollTop > 10) {
          dom.addClass( 'is-scroll', this.$body );
        } else {
          dom.removeClass( 'is-scroll', this.$body );
        }
      }

    },

    onColLeftHover: function(){

      dom.addClass( 'is-hover', this.$colLeft );

      dom.addClass( 'is-out', this.$colRight );
      dom.removeClass( 'is-out', this.$colLeft );
      dom.removeClass( 'is-hover', this.$colRight );

    },

    onColRightHover: function(){

      dom.removeClass( 'is-hover', this.$colLeft );

      dom.addClass( 'is-out', this.$colLeft );
      dom.removeClass( 'is-out', this.$colRight );
      dom.addClass( 'is-hover', this.$colRight );

    },

    onBurgerClick: function(){

      if( dom.hasClass('is-nav-opened', this.$body ) ){
        dom.removeClass( 'is-nav-opened', this.$body );
      } else {
        dom.addClass( 'is-nav-opened', this.$body );
      }
    },


    onNextItem: function(){


      this.$itemActive = dom.query('.is-item-active');
      this.activeItem = Array.prototype.indexOf.call( this.$wrapperBig, this.$itemActive );

      this.nextItem = this.activeItem + 1;

      if (this.activeItem >= this.wrapperBigArr - 1) {


      } else {
          this.$currentItem.innerHTML = '';
          this.$currentItem.innerHTML = this.$currentItem.innerHTML + (this.nextItem + 1);

      }

      if (this.activeItem >= this.wrapperBigArr) {


      } else {

        for (var i = 0; i < this.wrapperBigArr; i++) {

            // this.$wrapperAside[i].classList.remove('is-active');
            // this.$wrapperDesc[i].classList.remove('is-active');
            // this.$wrapperBig[i].classList.remove('is-active', 'is-item-active');

            dom.removeClass( 'is-item-active', this.$wrapperAside[i] );
            dom.removeClass( 'is-item-active', this.$wrapperDesc[i] );
            dom.removeClass( 'is-item-active', this.$wrapperBig[i] );

            dom.addClass( 'is-item-active', this.$wrapperAside[this.nextItem] );
            dom.addClass( 'is-item-active', this.$wrapperDesc[this.nextItem] );
            dom.addClass( 'is-item-active', this.$wrapperBig[this.nextItem] );

            // this.$wrapperAside[this.nextItem].classList.add('is-active');
            // this.$wrapperDesc[this.nextItem].classList.add('is-active');
            // this.$wrapperBig[this.nextItem].classList.add('is-active', 'is-item-active');

          } 
      }
    },


    onPrevItem: function(){

      this.$itemActive = dom.query('.is-item-active');
      this.activeItem = Array.prototype.indexOf.call( this.$wrapperBig, this.$itemActive );

      this.prevItem = this.activeItem - 1;

      if (this.activeItem <= 0) {


      } else {
          this.$currentItem.innerHTML = '';
          this.$currentItem.innerHTML = this.$currentItem.innerHTML + (this.prevItem + 1);

      }

      for (var i = 0; i < this.wrapperBigArr; i++) {

        if (this.activeItem <= 0) {

        } else {

          dom.removeClass( 'is-item-active', this.$wrapperAside[i] );
          dom.removeClass( 'is-item-active', this.$wrapperDesc[i] );
          dom.removeClass( 'is-item-active', this.$wrapperBig[i] );

          dom.addClass( 'is-item-active', this.$wrapperAside[this.prevItem] );
          dom.addClass( 'is-item-active', this.$wrapperDesc[this.prevItem] );
          dom.addClass( 'is-item-active', this.$wrapperBig[this.prevItem] );

        }      
      };
    },



    onNextNewsClick: function(){
      this.$newsActive = dom.query('.is-news-active');
      this.activeNews = Array.prototype.indexOf.call( this.$wrapperImg, this.$newsActive );

      this.nextNews = this.activeNews + 1;

      if (this.activeNews >= this.wrapperImgArr - 1) {


      } else {
          this.$currentNews.innerHTML = '';
          this.$currentNews.innerHTML = this.$currentNews.innerHTML + (this.nextNews + 1);

      }

      for (var i = 0; i < this.wrapperImgArr; i++) {

        if (this.activeNews >= this.wrapperImgArr) {

        } else {
          dom.removeClass( 'is-active', this.$wrapperImg[i]);
          dom.removeClass( 'is-active', this.$wrapperTxt[i]);
          dom.removeClass( 'is-news-active', this.$wrapperImg[i]);

          dom.addClass( 'is-active', this.$wrapperImg[this.nextNews]);
          dom.addClass( 'is-active', this.$wrapperTxt[this.nextNews]);
          dom.addClass( 'is-news-active', this.$wrapperImg[this.nextNews]);

        }      
      };
    },

    onPrevNewsClick: function(){
      this.$newsActive = dom.query('.is-news-active');
      this.activeNews = Array.prototype.indexOf.call( this.$wrapperImg, this.$newsActive );

      this.prevNews = this.activeNews - 1;

      if (this.activeNews <= 0) {


      } else {
          this.$currentNews.innerHTML = '';
          this.$currentNews.innerHTML = this.$currentNews.innerHTML + (this.prevNews + 1);

      }


      for (var i = 0; i < this.wrapperImgArr; i++) {

        if (this.activeNews <= 0) {

        } else {
          dom.removeClass( 'is-active', this.$wrapperImg[i]);
          dom.removeClass( 'is-active', this.$wrapperTxt[i]);
          dom.removeClass( 'is-news-active', this.$wrapperImg[i]);

          dom.addClass( 'is-active', this.$wrapperImg[this.prevNews]);
          dom.addClass( 'is-active', this.$wrapperTxt[this.prevNews]);
          dom.addClass( 'is-news-active', this.$wrapperImg[this.prevNews]);

        }      
      };

    },

    onPreviewClick: function(e){

      console.log('__onPreviewClick__');

      var src = e.currentTarget.getAttribute('src');
      console.log(src, this.$popin);

      this.$popinContainer.innerHTML = '';

      

      if (dom.hasClass('is-active', this.$popin)) {
        dom.removeClass('is-active', this.$popin);
      } else {
        dom.addClass('is-active', this.$popin);
        this.$popinContainer.innerHTML = '<img class="cell" src=' + src + '>';
      }


      

    },

    onTopClick: function(){



    },

     onPopinClick: function(){

      console.log('onPopinClick');
      dom.removeClass('is-active', this.$popin);
      this.$popinContainer.innerHTML = '';

     }
};

module.exports = Application;